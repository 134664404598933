.title {
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 60px;
  width: 100%;
  justify-content: center;
}
.text {
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: start;
}

.icon {
  width: 200px;
  height: 200px;
}

.detailWrapper {
  gap: 80px;
}
@media screen and (max-width: 768px) {
  .title {
    font-size: 30px;
  }

  .icon {
    width: 100px;
    height: 100px;
  }

  .detailWrapper {
    gap: 20px;
  }
}
