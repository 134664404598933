.App {
  text-align: center;
  font-family: "Architects Daughter", sans-serif;

  background: black;
  color: white;
  user-select: none;

  overflow: hidden;
}
