.container {
    width: 100%;
    font-family: 'Poppins';
    padding: 10px 30px;
    justify-content: space-between;
    align-items: center;
}

.logo{
    width: 60px;
    cursor: pointer;
}

.link {
    color: white;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
}

.linkGroup {
    gap: 10px;
    font-size: 14px;
    font-weight: 600;
}

@media screen and (max-width: 768px) {
    .linkGroup {
        display: none !important;
    }
    
}