.container {
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 40px;
  gap: 20px;
}
.icon {
  width: 100px;
}

@media screen and (max-width: 768px) {
  .container {
    margin-top: 30px;
  }
  .icon {
    width: 50px;
  }
}
