.container {
  width: 100%;
  padding: 10px 30px;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.bodyShapeIcon {
  position: absolute;
  top: -200px;
  z-index: 1;
}

.sheidIcon {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 10px 0px;
  }

  .sheidIcon {
    display: none;
  }
}
