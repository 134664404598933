// define a max width for the container
$maxStepWidth: 600px;
$maxStepDescriptionWidth: 500px;

.container {
  width: 100%;
  padding: 10px 30px;
  justify-content: start;
  align-items: center;
  position: relative;

  z-index: 3;
  margin-top: 100px;
  min-height: 1000px;
}

.step {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 10px 0px;

  width: $maxStepWidth;
}

.title {
  font-size: 50px;
  font-weight: 600;
}

.stepTitle {
  font-size: 24px;
  font-weight: 500;
}

.stepNumber {
  width: 80px;
  height: 80px;
  border: 4px solid #fff;
  border-radius: 1000%;

  align-items: center;
  justify-content: center;
}

.stepContent {
  max-width: $maxStepDescriptionWidth;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 10px 0px;
    margin-top: 10px;
    min-height: 600px;
  }

  .title {
    font-size: 30px;
  }

  .step {
    width: 320px;
  }

  .stepTitle {
    font-size: 14px;
  }
  .stepDescription {
    font-size: 12px;
  }
  .stepNumber {
    width: 40px;
    height: 40px;
    border-width: 1px;
    font-size: 12px;
    line-height: 10px;
  }

  .stepContent {
    max-width: 250px;
  }
}
