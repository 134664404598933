.container {
    width: 100%;
    padding: 0px 30px;
    justify-content: space-between;
    align-items: center;

    position: relative;

}

.bg{
    width: calc(100% - 60px);
    z-index: 11;
    bottom: 0px;
    position: absolute;
}

.main {
    z-index: 12;
    position: relative;
}

@media screen and (max-width: 768px) {
    .container {
        padding: 0;
    }
    .bg{
        width: calc(100%);
        bottom: 0px;
    }
    
}