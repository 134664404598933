.container {
  margin-top: 200px;
  margin-bottom: 100px;
  flex-direction: column;
  gap: 20px;
}

.title {
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 60px;
  width: 100%;
  justify-content: center;
}

.icon {
  width: 1200px;
}

@media screen and (max-width: 768px) {
  .container {
    margin-top: 100px;
    margin-bottom: 60px;
  }
  .title {
    font-size: 30px;
  }
}
